import React, { useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

export default function ClipboardCopy({ copyText }) {
  const [isCopied, setIsCopied] = useState(false);

  async function copyTextToClipboard(text) {
    if ('clipboard' in navigator) {
      return await navigator.clipboard.writeText(text);
    } else {
      return document.execCommand('copy', true, text);
    }
  }

  const handleCopyClick = () => {
    copyTextToClipboard(copyText)
      .then(() => {
        setIsCopied(true);

        setTimeout(() => {
          setIsCopied(false);
        }, 1500);
      })
      .catch((err) => {
        console.error(err);
      });
  };

  return (
    <Grid container spacing={1}>
      <Grid item xs={8}>
        <Typography
          variant="overline"
          display="block"
          sx={{
            backgroundColor: '#90ec80',
            border: '1px #144f0a solid',
            padding: '0px 10px',
            borderRadius: '5px',
          }}
          gutterBottom>
          {copyText}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <ContentCopyIcon sx={{ cursor: 'pointer' }} onClick={handleCopyClick} />
      </Grid>
      <Grid item xs={2}>
        {isCopied ? <CheckIcon color="success" /> : ''}
      </Grid>
    </Grid>
  );
}

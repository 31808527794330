import React, { useState, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { createTheme, ThemeProvider, useTheme } from '@mui/material/styles';
import { csCZ } from '@mui/material/locale';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from './Loader';
import UserPasswordReset from './UserPasswordReset';
import { getUsers, getAccountManager } from '../api';

const UserList = ({ accessToken, isSupervisor }) => {
  const DEFAULT_ROWS_PER_PAGE = 10;

  const [error, setError] = useState(null);
  const [users, setUsers] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [filterAccountManager, setFilterAccountManager] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const navigate = useNavigate();
  const theme = useTheme();

  const themeWithLocale = React.useMemo(() => createTheme(theme, csCZ), [theme]);

  const { id: accountManagerId } = useParams();

  const handleShowOrders = (event, userId) => {
    navigate(`/users/${userId}/orders`);
  };

  const handleResetFilter = () => {
    setFilterText('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, DEFAULT_ROWS_PER_PAGE));
    setPage(0);
  };

  useEffect(() => {
    const fetchAccountManager = async (id) => {
      try {
        const res = await getAccountManager(accessToken, id);

        if (res) {
          setFilterAccountManager(res.data);
        } else {
          console.error('Error fetching account manager');

          setError('Nepodařilo se stáhnout údaje o manažerovi');
        }
      } catch (err) {
        console.error(err);

        if (err.response) {
          setError(err.response.data.message);
        } else {
          setError('Nepodařilo se stáhnout údaje o manažerovi');
        }
      }
    };

    if (accountManagerId) {
      fetchAccountManager(accountManagerId);
    } else {
      setFilterAccountManager(null);
    }
  }, [accessToken, accountManagerId]);

  useEffect(() => {
    if (filterText === '') {
      setFilteredUsers(users);
    } else {
      setFilteredUsers(
        filteredUsers.filter((user) => {
          return (
            user.name.toLowerCase().includes(filterText.toLowerCase()) ||
            user.email.toLowerCase().includes(filterText.toLowerCase()) ||
            user.id.toString().includes(filterText.toLowerCase())
          );
        })
      );
    }

    setPage(0);
  }, [filterText]);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const res = await getUsers(
          accessToken,
          filterAccountManager ? filterAccountManager.id : null
        );

        if (res) {
          setUsers(res.data);
          setFilteredUsers(res.data);
        } else {
          console.error('Error fetching users');

          setError('Nepodařilo se stáhnout seznam míst');
        }
      } catch (err) {
        console.error(err);

        if (err.response) {
          setError(err.response.message);
        }

        if (err.message) {
          setError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchUsers();
  }, [accessToken, filterAccountManager]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', marginTop: 5 }}>
        Seznam míst{filterAccountManager && ` obchodníka ${filterAccountManager.name}`}
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {users.length > 0 && (
        <Box sx={{ margin: 2 }}>
          <TextField
            id="outlined-basic"
            placeholder="Id, jméno, e-mail, ..."
            variant="outlined"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <Button
            variant="contained"
            color="warning"
            onClick={handleResetFilter}
            sx={{ margin: 1 }}>
            Vymazat filtr
          </Button>
          <Typography variant="overline" display="block" sx={{ marginLeft: 1 }} gutterBottom>
            nalezeno {filteredUsers.length} míst
          </Typography>
        </Box>
      )}
      {filteredUsers.length > 0 ? (
        <Box sx={{ margin: 2 }}>
          <ThemeProvider theme={themeWithLocale}>
            <TableContainer component={Paper} sx={{ marginTop: 5 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold' }} align="right">
                      Id místa
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} align="right">
                      Jméno
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} align="right">
                      E-mail
                    </TableCell>
                    {isSupervisor && (
                      <TableCell sx={{ fontWeight: 'bold' }} align="right">
                        Aktivní
                      </TableCell>
                    )}
                    <TableCell sx={{ fontWeight: 'bold' }} align="right">
                      Počet objednávek
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }} align="right">
                      Zobrazit objednávky
                    </TableCell>
                    <TableCell />
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredUsers
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((user) => (
                      <TableRow
                        key={user.id}
                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                        <TableCell align="right">{user.id}</TableCell>
                        <TableCell align="right">{user.name}</TableCell>
                        <TableCell align="right">{user.email}</TableCell>
                        {isSupervisor && (
                          <TableCell align="right">
                            {user.active ? (
                              <Tooltip title="Ano">
                                <CheckIcon sx={{ color: 'green' }} />
                              </Tooltip>
                            ) : (
                              <Tooltip title="Ne">
                                <ClearIcon sx={{ color: 'red' }} />
                              </Tooltip>
                            )}
                          </TableCell>
                        )}
                        <TableCell align="right">{user.orders.length}</TableCell>
                        <TableCell align="right">
                          {user.orders.length > 0 && (
                            <Tooltip title="Objednávky">
                              <AssignmentIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) => handleShowOrders(e, user.id)}
                              />
                            </Tooltip>
                          )}
                        </TableCell>
                        <TableCell align="left">
                          {isSupervisor && (
                            <UserPasswordReset
                              accessToken={accessToken}
                              user={user}
                              isAccountManager={false}
                            />
                          )}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={filteredUsers.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
          </ThemeProvider>
        </Box>
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography component="h1" variant="h5">
              Zatím nemáte přiřazena žádná místa
            </Typography>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
};

export default UserList;

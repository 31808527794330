import React from 'react';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';

const TableRowHeaderStyled = ({ children, classes, hover, selected, sx }) => {
  const CustomTableRowStyled = styled(TableRow)`
    & {
      background-color: #009879;
    }
    & > th {
      color: white;
    }
  `;

  return (
    <CustomTableRowStyled classes={classes} hover={hover} selected={selected} sx={sx}>
      {children}
    </CustomTableRowStyled>
  );
};

export default TableRowHeaderStyled;

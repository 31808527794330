import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TextField from '@mui/material/TextField';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import Loader from './Loader';
import TableRowHeaderStyled from './TableRowHeaderStyled';
import TableRowStyled from './TableRowStyled';
import UserPasswordReset from './UserPasswordReset';
import { getAccountManagers } from '../api';

const AccountManagerList = ({ accessToken }) => {
  const [error, setError] = useState(null);
  const [accountManagers, setAccountManagers] = useState([]);
  const [filterText, setFilterText] = useState('');
  const [filteredAccountManagers, setFilteredAccountManagers] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();

  const handleShowUsers = (event, accountManagerId) => {
    navigate(`/account-managers/${accountManagerId}/users`);
  };

  const handleResetFilter = () => {
    setFilterText('');
  };

  useEffect(() => {
    if (filterText === '') {
      setFilteredAccountManagers(accountManagers);
    } else {
      setFilteredAccountManagers(
        filteredAccountManagers.filter((accountManager) => {
          return (
            accountManager.name.toLowerCase().includes(filterText.toLowerCase()) ||
            accountManager.email.toLowerCase().includes(filterText.toLowerCase()) ||
            accountManager.id.toString().includes(filterText.toLowerCase())
          );
        })
      );
    }
  }, [filterText]);

  useEffect(() => {
    const fetchAccountManagers = async () => {
      try {
        const res = await getAccountManagers(accessToken);

        if (res) {
          setAccountManagers(res.data);
          setFilteredAccountManagers(res.data);
        } else {
          setError('Nepodařilo se stáhnout seznam obchodníků');
        }
      } catch (err) {
        console.error(err);

        if (err.response) {
          setError(err.response.message);
        }

        if (err.message) {
          setError(err.message);
        }
      } finally {
        setIsLoading(false);
      }
    };

    fetchAccountManagers();
  }, [accessToken]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', marginTop: 5 }}>
        Seznam obchodníků
      </Typography>
      {error && <Alert severity="error">{error}</Alert>}
      {accountManagers.length > 0 && (
        <Box sx={{ margin: 2 }}>
          <TextField
            id="outlined-basic"
            placeholder="Id, jméno, e-mail, ..."
            variant="outlined"
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
          />
          <Button
            variant="contained"
            color="warning"
            onClick={handleResetFilter}
            sx={{ margin: 1 }}>
            Vymazat filtr
          </Button>
          <Typography variant="overline" display="block" sx={{ marginLeft: 1 }} gutterBottom>
            nalezeno {filteredAccountManagers.length} obchodníků
          </Typography>
        </Box>
      )}
      {filteredAccountManagers.length > 0 ? (
        <Box sx={{ margin: 2 }}>
          <TableContainer component={Paper} sx={{ marginTop: 5 }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
              <TableHead>
                <TableRowHeaderStyled>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    Id
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    Jméno
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    E-mail
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    Supervizor
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    Počet míst
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="right">
                    Zobrazit místa
                  </TableCell>
                  <TableCell />
                </TableRowHeaderStyled>
              </TableHead>
              <TableBody>
                {filteredAccountManagers.map((accountManager) => (
                  <TableRowStyled key={accountManager.id}>
                    <TableCell align="right">{accountManager.id}</TableCell>
                    <TableCell align="right">{accountManager.name}</TableCell>
                    <TableCell align="right">{accountManager.email}</TableCell>
                    <TableCell align="right">
                      {accountManager.is_supervisor ? (
                        <Tooltip title="Ano">
                          <CheckIcon sx={{ color: 'green' }} />
                        </Tooltip>
                      ) : (
                        <Tooltip title="Ne">
                          <ClearIcon sx={{ color: 'red' }} />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="right">
                      {!accountManager.is_supervisor ? accountManager.users.length : ''}
                    </TableCell>
                    <TableCell align="right">
                      {!accountManager.is_supervisor && accountManager.users.length > 0 && (
                        <Tooltip title="Místa">
                          <AssignmentIcon
                            sx={{ cursor: 'pointer' }}
                            onClick={(e) => handleShowUsers(e, accountManager.id)}
                          />
                        </Tooltip>
                      )}
                    </TableCell>
                    <TableCell align="left">
                      <UserPasswordReset
                        accessToken={accessToken}
                        user={accountManager}
                        isAccountManager={true}
                      />
                    </TableCell>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography component="h1" variant="h5">
              Zatím nejsou vytvořeni žádní obchodníci
            </Typography>
          </Box>
        </Container>
      )}
    </React.Fragment>
  );
};

export default AccountManagerList;

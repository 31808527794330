import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import ModalView from './ModalView';
import { processDownloadFile } from '../helpers';

const FilesModal = ({ accessToken, open, setOpen, orderNumber, files }) => {
  const { t } = useTranslation();

  const handleDownloadFile = async (file) => {
    try {
      await processDownloadFile(accessToken, file);
    } catch (err) {
      // setError('orders.errors.file_download_error');
    }
  };

  return (
    <ModalView
      open={open}
      setOpen={setOpen}
      headingTitle={
        <div>
          {t('files_modal.delivery_notes_for_order')} #<b>{orderNumber}</b>
        </div>
      }
      content={
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: 300 }} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }} align="right">
                  {t('files_modal.file_name')}
                </TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            <TableBody>
              {files.map((file) => (
                <TableRow key={file.id} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                  <TableCell align="right" component="th" scope="row">
                    {file.file_name}
                  </TableCell>
                  <TableCell align="left">
                    <Button
                      type="button"
                      variant="outlined"
                      className="btn btn-link"
                      onClick={() => handleDownloadFile(file)}
                      startIcon={<SaveAltIcon />}
                      sx={{ whiteSpace: 'nowrap' }}>
                      {t('files_modal.download')}
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      }
    />
  );
};

export default FilesModal;

import React, { useState } from 'react';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Autorenew from '@mui/icons-material/Autorenew';
import ClipboardCopy from './ClipboardCopy';
import SmallLoader from './SmallLoader';
import { resetUserPassword, resetAccountManagerPassword } from '../api';

const UserPasswordReset = ({ accessToken, user, isAccountManager }) => {
  const [isClicked, setIsClicked] = useState(false);
  const [sendEmail, setSendEmail] = useState(true);
  // const [error, setError] = useState(null);
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleResetUserPassword = async () => {
    setIsLoading(true);

    try {
      if (isAccountManager) {
        const res = await resetAccountManagerPassword(accessToken, user.id, user.email, sendEmail);

        setIsClicked(true);

        setPassword(res.data.data.password);
      } else {
        const res = await resetUserPassword(accessToken, user.id, user.email, sendEmail);

        setIsClicked(true);

        setPassword(res.data.data.password);
      }
    } catch (err) {
      console.error('Password reset error', err.response);

      // if (err.response.status === 401) {
      //   setError('Špatné přihlašovací údaje');
      // } else {
      //   setError(err.response.data.message);
      // }
    } finally {
      setIsLoading(false);
    }
  };

  const handleSendEmailWithPassword = (event) => {
    setSendEmail(event.target.checked);
  };

  if (isLoading) {
    return <SmallLoader />;
  }

  if (isClicked && password !== '') {
    return (
      <Grid container spacing={1}>
        <Grid item xs={7}>
          <ClipboardCopy copyText={password} />
        </Grid>
        <Grid item xs={1}>
          <Autorenew sx={{ cursor: 'pointer' }} onClick={() => handleResetUserPassword()} />
        </Grid>
        <Grid item xs={4}>
          <FormControlLabel
            control={
              <Checkbox
                defaultChecked={sendEmail}
                value={sendEmail}
                size="small"
                onChange={(e) => handleSendEmailWithPassword(e)}
              />
            }
            label="Zaslat e-mailem"
          />
        </Grid>
      </Grid>
    );
  }

  return (
    <>
      <Button
        type="button"
        variant="outlined"
        className="btn btn-link"
        onClick={() => handleResetUserPassword()}
        sx={{ whiteSpace: 'nowrap' }}>
        Resetovat heslo
      </Button>
      <FormControlLabel
        control={
          <Checkbox
            defaultChecked={true}
            value={sendEmail}
            size="small"
            onChange={(e) => handleSendEmailWithPassword(e)}
          />
        }
        label="Zaslat e-mailem"
      />
    </>
  );
};

export default UserPasswordReset;

import sha1 from 'sha1';
import { downloadFile } from '../api';

export function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth',
  });
}

export function scrollToBottom() {
  window.scrollTo({
    bottom: 0,
    behavior: 'smooth',
  });
}

export async function processDownloadFile(accessToken, file) {
  try {
    const hash = sha1(file.name + file.id);
    const res = await downloadFile(accessToken, file.id, hash);

    const blob = new Blob([res.data], { type: 'application/pdf' });
    const link = document.createElement('a');

    link.href = window.URL.createObjectURL(blob);
    link.download = file.file_name;
    link.click();
  } catch (err) {
    console.error('Error downloading file', err);

    throw err;
  }
}

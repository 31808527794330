import React, { useState } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import AppBar from '@mui/material/AppBar';
import Avatar from '@mui/material/Avatar';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Toolbar from '@mui/material/Toolbar';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import MenuIcon from '@mui/icons-material/Menu';
import EnvironmentLabel from './EnvironmentLabel';
import LanguageSelect from './LanguageSelect';
import { useAuth } from '../hooks/useAuth';

function ResponsiveAppBar() {
  const { user } = useAuth();
  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const isSelected = (path) => {
    return window.location.pathname === path;
  };

  const settings = [
    { name: t('menu.my_account'), path: '/user/account' },
    { name: t('menu.logout'), path: '/logout' },
  ];

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };

  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleNavigate = (path) => {
    handleCloseNavMenu();

    navigate(path);
  };

  const UserNameMenuItem = () => {
    if (!user.name || user.name === '') {
      return null;
    }

    return (
      <MenuItem key="account-name">
        <Typography textAlign="center" sx={{ fontWeight: 'bold' }}>
          {user.name}
        </Typography>
      </MenuItem>
    );
  };

  const getMenuItems = () => {
    let pages = [];

    if (user && user.is_account_manager) {
      pages = [{ name: 'Seznam míst', path: '/users' }];

      if (user.is_supervisor) {
        pages = [...pages, { name: 'Seznam obchodníků', path: '/account-managers' }];
      }
    } else {
      pages = [
        { name: t('menu.orders'), path: '/orders' },
        { name: t('menu.new_order'), path: '/orders/create' },
      ];
    }

    return pages;
  };

  const getMenuColor = () => {
    if (!user) {
      return 'default';
    }

    if (user.is_supervisor) {
      return 'secondary';
    }

    if (user.is_account_manager) {
      return 'info';
    }

    return 'primary';
  };

  const getMenuTitle = () => {
    if (!user) {
      return '';
    }

    if (user.is_supervisor) {
      return 'Supervizor';
    }

    if (user.is_account_manager) {
      return 'Obchodní zástupce';
    }

    return t('menu.customer') + (user.name && user.name !== '' ? ` - ${user.name}` : '');
  };

  if (!user) {
    return (
      <AppBar color="default" enableColorOnDark={true}>
        <Toolbar>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              textTransform: 'uppercase',
            }}>
            {t('menu.laundry')}
          </Typography>
          <EnvironmentLabel />
          <Typography>
            <LanguageSelect />
          </Typography>
        </Toolbar>
      </AppBar>
    );
  }

  return (
    <AppBar position="static" color={getMenuColor()} enableColorOnDark={true}>
      <Container maxWidth="xl">
        <Toolbar disableGutters>
          <Typography
            variant="h6"
            noWrap
            component="a"
            href="/"
            sx={{
              mr: 2,
              display: { xs: 'none', md: 'flex' },
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              textTransform: 'uppercase',
            }}>
            {t('menu.laundry')}
          </Typography>
          <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
            <IconButton
              size="large"
              aria-label="account of current user"
              aria-controls="menu-appbar"
              aria-haspopup="true"
              onClick={handleOpenNavMenu}
              color="inherit">
              <MenuIcon />
            </IconButton>
            <Menu
              id="menu-appbar"
              anchorEl={anchorElNav}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              open={Boolean(anchorElNav)}
              onClose={handleCloseNavMenu}
              sx={{
                display: { xs: 'block', md: 'none' },
              }}>
              {!user.is_account_manager && <LanguageSelect />}
              {getMenuItems().map((page) => (
                <MenuItem
                  key={page.name}
                  selected={isSelected(page.path)}
                  onClick={handleCloseNavMenu}>
                  <Typography textAlign="center">
                    <NavLink to={page.path}>{t(page.name)}</NavLink>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
          <Typography
            variant="h5"
            noWrap
            component="a"
            href=""
            sx={{
              mr: 2,
              display: { xs: 'flex', md: 'none' },
              flexGrow: 1,
              fontFamily: 'monospace',
              fontWeight: 700,
              letterSpacing: '.3rem',
              color: 'inherit',
              textDecoration: 'none',
              textTransform: 'uppercase',
            }}>
            {t('menu.laundry')}
          </Typography>
          <EnvironmentLabel />
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            {getMenuItems().map((page) => (
              <Button
                key={page.name}
                onClick={handleNavigate.bind(this, page.path)}
                sx={{
                  my: 2,
                  color: 'white',
                  display: 'block',
                  backgroundColor: isSelected(page.path) ? 'green' : 'default',
                }}>
                {t(page.name)}
              </Button>
            ))}
          </Box>
          {!user.is_account_manager && (
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
              <LanguageSelect />
            </Box>
          )}
          <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
            <Typography
              variant="h6"
              noWrap
              component="a"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 600,
                letterSpacing: '.3rem',
                color: 'inherit',
                textDecoration: 'none',
              }}>
              {t(getMenuTitle())}
            </Typography>
          </Box>
          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Open settings">
              <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                <Avatar alt="" src="/static/images/avatar/2.jpg" />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}>
              <UserNameMenuItem />
              {settings.map((setting) => (
                <MenuItem key={setting.name} onClick={handleCloseUserMenu}>
                  <Typography textAlign="center">
                    <NavLink to={setting.path}>{t(setting.name)}</NavLink>
                  </Typography>
                </MenuItem>
              ))}
            </Menu>
          </Box>
        </Toolbar>
      </Container>
    </AppBar>
  );
}

export default ResponsiveAppBar;

import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import SvgIcon from '@mui/material/SvgIcon';
import { ReactComponent as CsFlagIcon } from '../cs.svg';
import { ReactComponent as EnFlagIcon } from '../en.svg';
import { ReactComponent as UkFlagIcon } from '../uk.svg';

function LanguageSelect() {
  const { i18n } = useTranslation();

  const languages = {
    cs: {
      name: 'Česky',
      icon: CsFlagIcon,
    },
    en: {
      name: 'English',
      icon: EnFlagIcon,
    },
    uk: {
      name: 'українська',
      icon: UkFlagIcon,
    },
  };

  return (
    <>
      {Object.keys(languages)
        .filter((lng) => lng !== i18n.resolvedLanguage)
        .map((lng) => (
          <Button
            key={lng}
            style={{ fontWeight: i18n.resolvedLanguage === lng ? 'bold' : 'normal' }}
            type="submit"
            onClick={() => i18n.changeLanguage(lng)}
            aria-label={languages[lng].name}>
            <SvgIcon component={languages[lng].icon} inheritViewBox />
          </Button>
        ))}
    </>
  );
}

export default LanguageSelect;

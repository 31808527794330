import React, { Suspense } from 'react';
import Container from '@mui/material/Container';
import Loader from './components/Loader';
import ResponsiveAppBar from './components/ResponsiveAppBar';
import Router from './routes';
import { AuthProvider } from './hooks/useAuth';
import './App.css';

function App() {
  return (
    <Suspense fallback={<Loader />}>
      <AuthProvider>
        <ResponsiveAppBar />
        <Container>
          <Router />
        </Container>
      </AuthProvider>
    </Suspense>
  );
}

export default App;

import React, { useState, useEffect, useMemo } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import dayjs from 'dayjs';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import AssignmentIcon from '@mui/icons-material/Assignment';
import CommentIcon from '@mui/icons-material/Comment';
import EditIcon from '@mui/icons-material/Edit';
import FindInPageOutlinedIcon from '@mui/icons-material/FindInPageOutlined';
import SaveAltIcon from '@mui/icons-material/SaveAlt';
import FilesModal from './FilesModal';
import Loader from './Loader';
import MaterialModal from './MaterialModal';
import TableRowHeaderStyled from './TableRowHeaderStyled';
import TableRowStyled from './TableRowStyled';
import { getUser, getOrders, getUserOrders } from '../api';
import { useAuth } from '../hooks/useAuth';
import { processDownloadFile } from '../helpers';
import { createThemeWithLocale } from '../helpers/utils';

const OrderList = ({ accessToken, isAccountManager }) => {
  const DEFAULT_ROWS_PER_PAGE = 10;

  const [error, setError] = useState(null);
  const [orders, setOrders] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [materialModalOpen, setMaterialModalOpen] = useState(false);
  const [materialModalOrderNumber, setMaterialModalOrderNumber] = useState(null);
  const [materialModalMaterials, setMaterialModalMaterials] = useState([]);
  const [materialModalComment, setMaterialModalComment] = useState(null);
  const [materialModalIsCommentView, setMaterialModalIsCommentView] = useState(false);
  const [filesModalOpen, setFilesModalOpen] = useState(false);
  const [filesModalOrderNumber, setFilesModalOrderNumber] = useState(null);
  const [filesModalFiles, setFilesModalFiles] = useState([]);
  const [user, setUser] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(DEFAULT_ROWS_PER_PAGE);
  const [filterParams, setFilterParams] = useState({});
  const [totalResults, setTotalResults] = useState(0);
  const navigate = useNavigate();
  const { logout } = useAuth();
  const { t, i18n } = useTranslation();
  const theme = useTheme();

  const themeWithLocale = useMemo(
    () => createThemeWithLocale(theme, i18n.language),
    [i18n.language, theme]
  );

  const { id: userId } = useParams();

  const handleEditOrder = (event, orderUuid) => {
    navigate(`/orders/edit/${orderUuid}`);
  };

  const handleOpenMaterialModal = (event, orderId, materials, comment, isCommentVew) => {
    setMaterialModalOrderNumber(orderId);
    setMaterialModalMaterials(materials);
    setMaterialModalOpen(true);
    setMaterialModalComment(comment);
    setMaterialModalIsCommentView(isCommentVew);
  };

  const handleOpenFilesModal = (event, orderNumber, files) => {
    setFilesModalOrderNumber(orderNumber);
    setFilesModalFiles(files);
    setFilesModalOpen(true);
  };

  const handleDownloadFile = async (file) => {
    try {
      await processDownloadFile(accessToken, file);
    } catch (err) {
      const errorMessage = err.response?.statusText || err.message;
      setError(t('orders.errors.file_download_error') + ': ' + errorMessage);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    setFilterParams({});
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, DEFAULT_ROWS_PER_PAGE));
    setPage(0);
  };

  useEffect(() => {
    const fetchUser = async (id) => {
      try {
        const res = await getUser(accessToken, id);

        if (res) {
          setUser(res.data);
        } else {
          console.error('Error fetching user');

          setError('orders.errors.user_fetch_error');
        }
      } catch (err) {
        console.error(err);

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    if (userId) {
      fetchUser(userId);
    }
  }, [accessToken, userId]);

  useEffect(() => {
    const fetchOrders = async () => {
      try {
        let res = null;
        setIsLoading(true);

        if (isAccountManager) {
          if (!userId) {
            throw new Error('Missing user ID');
          }

          res = await getUserOrders(accessToken, userId, rowsPerPage, page + 1, filterParams);
        } else {
          res = await getOrders(accessToken, rowsPerPage, page + 1, filterParams);
        }

        if (res) {
          setOrders(res.data.data);
          setTotalResults(res.data.meta.total);
        } else {
          console.error('Error fetching orders');

          setError('orders.errors.orders_fetch_error');
        }
      } catch (err) {
        console.error(err);

        if (err.response.status === 401) {
          logout();
          navigate('/');
        }

        setError(err.response.message);
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrders();
  }, [accessToken, isAccountManager, userId, rowsPerPage, page, filterParams]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container
      component="main"
      sx={{
        marginTop: 0,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}>
      <Typography component="h1" variant="h4" sx={{ fontWeight: 'bold', marginTop: 4 }}>
        {t('orders.order_list')}
        {user && ` místa "${user.name}"`}
      </Typography>
      {error && <Alert severity="error">{t(error)}</Alert>}
      {orders.length > 0 ? (
        <Box>
          <ThemeProvider theme={themeWithLocale}>
            <TableContainer component={Paper} sx={{ marginTop: 3 }}>
              <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                  <TableRowHeaderStyled>
                    <TableCell sx={{ fontWeight: 'bold' }}>{t('orders.order_number')}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>{t('orders.order_id')}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>
                      {t('orders.material_items_count')}
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>{t('orders.when_to_pick_up')}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>{t('orders.when_to_deliver')}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>{t('orders.box_count')}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>{t('orders.date_time')}</TableCell>
                    <TableCell sx={{ fontWeight: 'bold' }}>{t('orders.delivery_notes')}</TableCell>
                    {!isAccountManager && (
                      <TableCell sx={{ fontWeight: 'bold' }}>{t('orders.actions')}</TableCell>
                    )}
                  </TableRowHeaderStyled>
                </TableHead>
                <TableBody>
                  {orders.map((order) => (
                    <TableRowStyled key={order.uuid}>
                      <TableCell>
                        {order.is_cancelled
                          ? t('orders.is_cancelled')
                          : order.order_number > 0
                          ? order.order_number
                          : t('orders.not_assigned')}
                      </TableCell>
                      <TableCell>#{order.order_id}</TableCell>
                      <TableCell>
                        <Grid container wrap="nowrap">
                          <Grid item>
                            <Typography gutterBottom>{order.materials.length}</Typography>
                          </Grid>
                          <Grid item sx={{ marginLeft: 1, marginRight: 1 }}>
                            <Tooltip title={t('orders.show_material_list')}>
                              <FindInPageOutlinedIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) =>
                                  handleOpenMaterialModal(
                                    e,
                                    order.order_number,
                                    order.materials,
                                    order.comment,
                                    false
                                  )
                                }
                              />
                            </Tooltip>
                          </Grid>
                          <Grid item>
                            {order.comment !== null && order.comment !== '' && (
                              <Tooltip title={t('orders.order_has_comment')}>
                                <CommentIcon
                                  sx={{ cursor: 'pointer' }}
                                  onClick={(e) =>
                                    handleOpenMaterialModal(
                                      e,
                                      order.order_number,
                                      order.materials,
                                      order.comment,
                                      true
                                    )
                                  }
                                />
                              </Tooltip>
                            )}
                          </Grid>
                        </Grid>
                      </TableCell>
                      <TableCell>
                        {dayjs(new Date(order.when_to_pick_up)).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell>
                        {dayjs(new Date(order.when_to_deliver)).format('DD.MM.YYYY')}
                      </TableCell>
                      <TableCell>{order.box_count}</TableCell>
                      <TableCell>
                        {dayjs(new Date(order.date_time)).format('DD.MM.YYYY HH:mm:ss')}
                      </TableCell>
                      <TableCell>
                        {order.files.length > 0 && (
                          <Grid container wrap="nowrap">
                            <Grid item>
                              <Button
                                type="button"
                                variant="contained"
                                color="primary"
                                className="btn btn-link"
                                onClick={() => handleDownloadFile(order.files[0])}
                                sx={{ whiteSpace: 'nowrap' }}
                                startIcon={<SaveAltIcon />}>
                                {order.files[0].name}
                              </Button>
                            </Grid>
                            {order.files.length > 1 && (
                              <Grid item>
                                <Button
                                  type="button"
                                  variant="contained"
                                  color="primary"
                                  className="btn btn-link"
                                  onClick={(e) =>
                                    handleOpenFilesModal(e, order.order_number, order.files)
                                  }
                                  sx={{ whiteSpace: 'nowrap' }}>
                                  <AssignmentIcon />
                                </Button>
                              </Grid>
                            )}
                          </Grid>
                        )}
                      </TableCell>
                      {!isAccountManager && (
                        <TableCell>
                          {order.is_editable || order.is_partially_editable ? (
                            <Tooltip title={t('orders.edit')}>
                              <EditIcon
                                sx={{ cursor: 'pointer' }}
                                onClick={(e) => handleEditOrder(e, order.uuid)}
                              />
                            </Tooltip>
                          ) : (
                            <Tooltip title={t('orders.cannot_edit')}>
                              <span>{t('orders.cannot_edit')}</span>
                            </Tooltip>
                          )}
                        </TableCell>
                      )}
                    </TableRowStyled>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={totalResults}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              showFirstButton
              showLastButton
            />
            <MaterialModal
              open={materialModalOpen}
              setOpen={setMaterialModalOpen}
              orderNumber={materialModalOrderNumber}
              materials={materialModalMaterials}
              comment={materialModalComment}
              isCommentView={materialModalIsCommentView}
            />
            <FilesModal
              accessToken={accessToken}
              open={filesModalOpen}
              setOpen={setFilesModalOpen}
              orderNumber={filesModalOrderNumber}
              files={filesModalFiles}
            />
          </ThemeProvider>
        </Box>
      ) : (
        <Container component="main" maxWidth="xs">
          <Box
            sx={{
              marginTop: 8,
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
            }}>
            <Typography component="h1" variant="h5">
              {t('orders.no_orders_yet')}
            </Typography>
          </Box>
        </Container>
      )}
    </Container>
  );
};

export default OrderList;

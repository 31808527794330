import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import Link from '@mui/material/Link';
import Switch from '@mui/material/Switch';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Loader from './Loader';
import { passwordReset, accountManagerPasswordReset } from '../api';

const PasswordReset = () => {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [isAccountManager, setIsAccountManager] = useState(false);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      if (isAccountManager) {
        await accountManagerPasswordReset(username);
      } else {
        await passwordReset(parseInt(username), email);
      }

      setSuccess(true);
    } catch (err) {
      console.error('Login error', err);

      if (err.response.status === 401) {
        setError('password_reset.errors.wrong_credentials');
      } else {
        setError(err.response.data.message);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSwitchIsAccountManager = (event) => {
    setIsAccountManager(event.target.checked);
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography component="h1" variant="h5">
          {t('password_reset.lost_password')}
        </Typography>
        {success && (
          <Alert severity="success">{t('password_reset.new_password_sent_to_email')}</Alert>
        )}
        {error && <Alert severity="error">{t(error)}</Alert>}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <Box sx={{ float: 'right' }}>
            <FormControlLabel
              control={
                <Switch checked={isAccountManager} onChange={handleSwitchIsAccountManager} />
              }
              label={t('password_reset.account_manager')}
            />
          </Box>
          {!isAccountManager && (
            <TextField
              margin="normal"
              required
              fullWidth
              name="username"
              label={t('password_reset.username')}
              id="username"
              value={username}
              autoComplete="username"
              onChange={handleUsernameChange}
              autoFocus
            />
          )}
          <TextField
            margin="normal"
            required
            fullWidth
            name="email"
            label={t('password_reset.email')}
            type="email"
            id="email"
            value={email}
            autoComplete="email"
            onChange={handleEmailChange}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {t('password_reset.submit')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/" variant="body2">
                {t('password_reset.back_to_login')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default PasswordReset;

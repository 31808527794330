const LOCAL = 'local';
const TEST = 'test';
const PROD = 'production';

function getEnvironment(hostname) {
  switch (hostname) {
    case 'localhost':
      return LOCAL;
    case 'test.pradelna.urbandigital.cz':
      return TEST;
    default:
      return PROD;
  }
}

function getApiUrl(env) {
  if (env === LOCAL) {
    return 'http://localhost:8333';
  }

  if (env === TEST) {
    return 'https://test.pradelna.urbandigital.cz';
  }

  return 'https://pradelna.urbandigital.cz';
}

export const ENV_LOCAL = LOCAL;
export const ENV_TEST = TEST;
export const ENV_PROD = PROD;
export const ENVIRONMENT = getEnvironment(window.location.hostname);
export const API_BASE_URL = getApiUrl(ENVIRONMENT);

// export const API_BASE_URL = process.env.REACT_APP_ENV || 'https://pradelna.urbandigital.cz';
// export const API_BASE_URL = process.env.NODE_ENV === 'development'
//     ? 'http://localhost:8333'
//     : 'https://pradelna.urbandigital.cz';

import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { v4 as uuidv4 } from 'uuid';
import AutocompleteRow from './AutocompleteRow';
import QuantityBox from './QuantityBox';

const EMPTY_ORDER_ITEM = {
  id: '',
  title: '',
  title_cs: '',
  title_en: '',
  title_uk: '',
  quantity: 0,
  isFavorite: false,
};

const MaterialList = ({ allMaterials, orderItems, arrayHelpers, gridRowStyle, isEditMode }) => {
  const { t, i18n } = useTranslation();

  const addOrderItem = () => {
    const emptyItem = { ...EMPTY_ORDER_ITEM, id: uuidv4() };

    arrayHelpers.push(emptyItem);
  };

  const handleRemoveOrderItemRow = (index) => {
    arrayHelpers.remove(index);
  };

  const handleOrderItemQuantityChange = (value, index) => {
    const newOrderItem = { ...orderItems[index], quantity: value };

    arrayHelpers.replace(index, newOrderItem);
  };

  const handleOrderItemChange = (value, index) => {
    arrayHelpers.replace(index, value);
  };

  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{
          ...gridRowStyle,
          borderTop: '1px solid rgb(224, 224, 224);',
          backgroundColor: '#009879',
          color: 'white',
        }}>
        <Grid container item spacing={0}>
          <Grid item xs={6} sx={{ fontWeight: 'bold' }}>
            {t('order_form.material_name')}
          </Grid>
          <Grid item xs={4} sx={{ fontWeight: 'bold' }}>
            {t('order_form.material_quantity')}
          </Grid>
          <Grid item xs={2} />
        </Grid>
      </Grid>
      {orderItems.map((material, index) => {
        const materialId = material.id;
        const materialTitle = material[`title_${i18n.language}`];
        const selectedIds = orderItems.map((material) => material.id);
        const selectedTitles = orderItems.map((material) => material.title);

        const isAlreadySelected = (mat) => {
          const isIdSelected = selectedIds.includes(mat.id);
          const isIdCurrent = mat.id === materialId;
          const isTitleSelected = selectedTitles.includes(mat.title);
          const isTitleCurrent = mat.title === material.title;

          return !(isIdSelected || isIdCurrent || isTitleSelected || isTitleCurrent);
        };

        const filteredMaterials = allMaterials.filter(isAlreadySelected);

        if (!material.isFavorite) {
          return (
            <AutocompleteRow
              allMaterials={filteredMaterials}
              material={material}
              onQuantityChange={handleOrderItemQuantityChange}
              onRemoveRow={handleRemoveOrderItemRow}
              onItemChange={handleOrderItemChange}
              orderItems={orderItems}
              index={index}
              key={materialId}
              isFocus={!isEditMode && index === orderItems.length - 1}
              gridRowStyle={gridRowStyle}
            />
          );
        }

        return (
          <Grid container spacing={0} sx={gridRowStyle} key={materialId}>
            <Grid container item spacing={0}>
              <Grid item xs={6}>
                <Typography variant="overline" display="block" gutterBottom noWrap={true}>
                  {materialTitle}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <QuantityBox
                  onChange={(value) => handleOrderItemQuantityChange(value, index)}
                  initialValue={material.quantity}
                  minimumValue={0}
                  id={`quantity-${material.id}`}
                />
              </Grid>
              <Grid item xs={2}></Grid>
            </Grid>
          </Grid>
        );
      })}
      <Grid container spacing={0} sx={gridRowStyle}>
        <Grid container item spacing={0}>
          <Grid item xs={12} sx={{ textAlign: 'center' }}>
            <Button
              type="button"
              variant="outlined"
              className="btn btn-link"
              onClick={addOrderItem}
              sx={{ whiteSpace: 'nowrap' }}>
              <AddCircleIcon sx={{ color: 'green', margin: 1 }} />
              &nbsp;{t('order_form.add_material')}
            </Button>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
};

export default MaterialList;

import React from 'react';
import TableRow from '@mui/material/TableRow';
import styled from '@emotion/styled';

const TableRowStyled = ({ children, classes, hover, selected, sx }) => {
  const CustomTableRowStyled = styled(TableRow)`
    &:nth-of-type(odd) {
      background-color: #eee;
    }
    &:nth-of-type(even) {
      background-color: #fff;
    }
    &:last-child td,
    &:last-child th {
      border: 0;
    }
  `;

  return (
    <CustomTableRowStyled classes={classes} hover={hover} selected={selected} sx={sx}>
      {children}
    </CustomTableRowStyled>
  );
};

export default TableRowStyled;

import * as locales from '@mui/material/locale';
import { createTheme } from '@mui/material/styles';

export const createThemeWithLocale = (theme, locale) => {
  const availableLocales = {
    cs: 'csCZ',
    en: 'enUS',
    uk: 'ukUA',
  };
  return createTheme(theme, locales[availableLocales[locale]]);
};

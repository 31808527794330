import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import Alert from '@mui/material/Alert';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import Container from '@mui/material/Container';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import Link from '@mui/material/Link';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Loader from './Loader';
import { login, accountManagerLogin, getUserAccount, getAccountManagerUserAccount } from '../api';
import { useAuth } from '../hooks/useAuth';
import { useLocalStorage } from '../hooks/useLocalStorage';

const LoginForm = () => {
  const { login: onLogin } = useAuth();

  const { setItem, getItem } = useLocalStorage();
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  useEffect(() => {
    const fetchOrders = () => {
      const rememberDefaults = {
        username: '',
        password: '',
        rememberMe: false,
      };

      const remember = JSON.parse(getItem('remember')) || rememberDefaults;

      setUsername(remember.username);
      setPassword(remember.password);
      setRememberMe(remember.rememberMe);
    };

    fetchOrders();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    setIsLoading(true);

    try {
      if (username.includes('@')) {
        await handleAccountManagerLogin();
      } else {
        await handleUserLogin();
      }
    } catch (err) {
      console.error('Login error', err);

      if (err.response) {
        if (err.response.status === 401) {
          setError('login.errors.wrong_credentials');
        } else if (err.response.status === 403) {
          setError('login.errors.account_not_active');
        } else {
          setError(err.response.data.message);
        }
      } else {
        setError(err);
      }
    } finally {
      setIsLoading(false);
    }
  };

  const handleUserLogin = async () => {
    const res = await login(parseInt(username), password);

    const user = await getUserAccount(res.data.data.access_token);

    const userData = {
      ...res.data.data,
      id: user.data.id,
      name: user.data.name,
      is_account_manager: false,
      is_supervisor: false,
    };

    onLogin(userData);

    handleRememberMe();

    navigate('/orders');
  };

  const handleAccountManagerLogin = async () => {
    const res = await accountManagerLogin(username, password);

    const user = await getAccountManagerUserAccount(res.data.data.access_token);

    const userData = {
      ...res.data.data,
      id: user.data.id,
      name: user.data.name,
      is_account_manager: true,
      is_supervisor: user.data.is_supervisor,
    };

    onLogin(userData);

    handleRememberMe();

    navigate('/users');
  };

  const handleUsernameChange = (event) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  };

  const handleRememberMeChange = (event) => {
    setRememberMe(event.target.checked);
  };

  const handleRememberMe = () => {
    if (rememberMe) {
      setItem(
        'remember',
        JSON.stringify({
          username,
          password,
          rememberMe,
        })
      );
    } else {
      setItem('remember', JSON.stringify(''));
    }
  };

  if (isLoading) {
    return <Loader />;
  }

  return (
    <Container component="main" maxWidth="xs">
      <Box
        sx={{
          marginTop: 8,
          paddingTop: 4,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}>
        <Typography component="h1" variant="h5">
          {t('login.login')}
        </Typography>
        {error && <Alert severity="error">{t(error)}</Alert>}
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="username"
              label={t('login.username')}
              id="username"
              value={username}
              autoComplete="username"
              onChange={handleUsernameChange}
              autoFocus
            />
          </FormControl>
          <FormControl sx={{ width: '100%' }}>
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label={t('login.password')}
              type={showPassword ? 'text' : 'password'}
              id="password"
              value={password}
              autoComplete="current-password"
              onChange={handlePasswordChange}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end">
                      {showPassword ? <VisibilityOff /> : <Visibility />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />
          </FormControl>
          <FormControlLabel
            control={
              <Checkbox
                value={rememberMe}
                checked={rememberMe}
                color="primary"
                onChange={handleRememberMeChange}
              />
            }
            label={t('login.remember_me')}
          />
          <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
            {t('login.log_in')}
          </Button>
          <Grid container>
            <Grid item xs>
              <Link href="/password-reset" variant="body2">
                {t('login.forgot_password')}
              </Link>
            </Grid>
            <Grid item xs>
              <Link href="mailto:info@urbandigital.cz" variant="body2" style={{ float: 'right' }}>
                {t('login.contact_us')}
              </Link>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default LoginForm;

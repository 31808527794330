import React from 'react';
import Chip from '@mui/material/Chip';
import { ENVIRONMENT, ENV_PROD } from '../config';

export default function EnvironmentLabel() {
  if (ENVIRONMENT === ENV_PROD) {
    return null;
  }

  return <Chip label={ENVIRONMENT.toUpperCase()} color="error" sx={{ m: 1 }} />;
}

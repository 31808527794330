import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import ModalView from './ModalView';
import TableRowHeaderStyled from './TableRowHeaderStyled';
import TableRowStyled from './TableRowStyled';
import { scrollToBottom } from '../helpers';

const MaterialModal = ({
  open,
  setOpen,
  orderNumber,
  materials,
  comment,
  isCommentView = false,
}) => {
  const { t, i18n } = useTranslation();

  const renderOrderNumber = (number) => {
    return number > 0 ? (
      <div>
        {t('material_modal.material_list')} #<b>{number}</b>
      </div>
    ) : (
      <div>Seznam materiálu</div>
    );
  };

  useEffect(() => {
    if (isCommentView === true) {
      scrollToBottom();
    }
  }, [isCommentView]);

  return (
    <ModalView
      open={open}
      setOpen={setOpen}
      headingTitle={renderOrderNumber(orderNumber)}
      content={
        <>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: 300 }} aria-label="simple table">
              <TableHead>
                <TableRowHeaderStyled>
                  <TableCell sx={{ fontWeight: 'bold' }} align="left">
                    {t('material_modal.material_name')}
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }} align="left">
                    {t('material_modal.material_quantity')}
                  </TableCell>
                </TableRowHeaderStyled>
              </TableHead>
              <TableBody>
                {materials.map((material) => (
                  <TableRowStyled key={material.id}>
                    <TableCell align="left">{material[`title_${i18n.language}`]}</TableCell>
                    <TableCell align="left">
                      {material.quantity} {t('material_modal.pieces')}
                    </TableCell>
                  </TableRowStyled>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          {comment !== null && comment !== '' && (
            <Box sx={{ padding: 3, border: '1px #ff0000 solid', backgroundColor: 'lightyellow' }}>
              <strong>{t('material_modal.comment')}:</strong> {comment}
            </Box>
          )}
        </>
      }
    />
  );
};

export default MaterialModal;
